;(function ($) {
    $('#slider .slides').on('init', function(event, slick){
      $(slick.$slides.get(0)).find('.content').addClass('fadeInRight');
    });

    $('#slider .slides').slick({
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-angle-left-circle"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-angle-right-circle"></span></button>',
        autoplay: true,
        autoplaySpeed: 7500,
        draggable: false,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: false
              }
            },
        ]
    });

    $('#slider .slides').on('beforeChange', function(event, slick, slideIndex) {
      $(slick.$slides.get(slideIndex)).find('.content').removeClass('fadeInRight');
    });

    $('#slider .slides').on('afterChange', function(event, slick, slideIndex) {
      $(slick.$slides.get(slideIndex)).find('.content').addClass('fadeInRight');
    });

    $('#single-case-study .gallery').slick({
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-left-arrow"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-right-arrow"></span></button>'
    })

    $('#latest-news .articles').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-left-arrow"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-right-arrow"></span></button>',
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            }
        ]
    });

    $('#clients .clients').slick({
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-angle-left-circle"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-angle-right-circle"></span></button>',
        appendArrows: '#clients .clients-navigation',
        rows: 3,
        slidesPerRow: 4
    });

    $('#clients .clients-navigation .slick-arrow').on('click', function () {
        var slider = $('#clients .clients');

        return $(this).hasClass('slick-prev') ? slider.slick('slickPrev') : slider.slick('slickNext');
    });

    $('#case-studies .case-studies').slick({
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-angle-left-circle"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-angle-right-circle"></span></button>',
        appendArrows: '#case-studies .case-studies-navigation',
        rows: 3,
        slidesPerRow: 4
    });

    $('#case-studies .case-studies-navigation .slick-arrow').on('click', function () {
        var slider = $('#case-studies .case-studies');

        return $(this).hasClass('slick-prev') ? slider.slick('slickPrev') : slider.slick('slickNext');
    })

    var div = document.querySelector('.menu-header-menu-container'),
        clone = div.cloneNode(true); // true means clone all childNodes and all event handlers
    clone.id = "menu-mobile";
    document.body.insertBefore(clone, document.getElementById('panel'));

    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu-mobile'),
        'padding': 256,
        'tolerance': 70
    });

    document.querySelector('.toggle-nav').addEventListener('click', function(e) {
        e.preventDefault();
        slideout.toggle();
    });

    if ($(window).width() < 1200) {
        $('#menu-main-navigation > li.menu-item-has-children > a').on('click', function(e) {
            var $parent = $(this).parent();

            if (!$parent.hasClass('hover')) {
                e.preventDefault();
                $('#menu-main-navigation > li.menu-item-has-children').removeClass('hover');
            }

            $parent.toggleClass('hover');
        });

        $('#menu-main-navigation > li.menu-item-has-children').on('mouseover', function() {
            $(this).siblings('li.menu-item-has-children').removeClass('hover');
        });

        $('body').on('click', function(e) {
            if (!$(e.target).parents('li.menu-item-has-children.hover').length) {
                $('li.menu-item-has-children.hover').removeClass('hover');
            }
        });
    }
    $(document).on('scroll', function() {
       var scrollTop = $(document).scrollTop();
       var scrollStop = 350;
       var marginStop = 46;
       var marginStart = 90;
       var logoStartHeight = 103;
       var logoStopHeight = 60;
       var opacityStart = 1.0
       var opacityStop = 0.8;

       var valueScrolled = scrollTop > scrollStop ? 1 : scrollTop/scrollStop;
       var marginScrollFactor = (marginStart - marginStop);
       var logoScrollFactor = (logoStartHeight - logoStopHeight);
       var opacityScrollFactor = (opacityStart - opacityStop);
       $('.contact-details').css('margin-bottom', marginStart - (valueScrolled * marginScrollFactor) + 'px');
       $('.logo').css('height', logoStartHeight - (valueScrolled * logoScrollFactor) + 'px');
       $('#site-header').css('background', 'rgba(255,255,255,'+(opacityStart - (valueScrolled * opacityScrollFactor)).toFixed(3)+')');
    });
})(jQuery);
